// Packages
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// Services
import reportService from "../../services/report";
import { initreports } from "../../reducers/reportReducer";
// Components
import Navbar from "../navigation/Navbar";
import Notification from "../utility/Notification";
import FloatingButton from "../utility/FloatingButton";
import Report from "./Report";

const Reports = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  // If no valid user, force login
  if (!user) {
    history.push("/");
  }
  const notification = useSelector((state) => state.notification);
  const [reports, setreports] = useState([]);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  // Fetch Reports
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setloading(true);
        const results = await reportService.getReports(user.id);
        console.log(results);
        setreports(results);
        dispatch(initreports(results));
        setloading(false);
      }
    };
    fetchData();
  }, [user, dispatch]);
  // Create New Report
  const createNewReport = () => {
    history.push("/newreport");
  };
  // Show Reports
  const showReports = () => {
    return reports && reports.length > 0 ? (
      reports.map((report) => (
        <Link key={report._id} to={`/reportdetails/${report._id}`}>
          <Report report={report} />
        </Link>
      ))
    ) : (
      <h3 className="text-xl text-purple-900 text-center my-8">
        No Reports Yet... !
      </h3>
    );
  };
  // Show Loader
  const showLoader = () => {
    return (
      <h3 className="text-xl text-purple-900 text-center my-8">Loading....</h3>
    );
  };

  return (
    <>
      <Navbar title="Daily Reports" />
      <section className="mx-auto">
        <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
          Daily Reports
        </h1>
        {notification ? <Notification notification={notification} /> : null}
        <div>{loading ? showLoader() : showReports()}</div>
        <FloatingButton handleClick={createNewReport} />
      </section>
    </>
  );
};

export default Reports;
