import authService from "../services/auth";
import { createNotification } from "./notificationReducer";

let authToken = null;

const authReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_USER":
      return action.user;
    case "SAVE_USER":
      return action.user;
    case "CLEAR_USER":
      return action.user;
    default:
      return state;
  }
};

export const setFilter = (filter) => {
  return {
    type: "SET_FILTER",
    filter,
  };
};

const saveUserToLocalStorage = (user) => {
  JSON.stringify(
    window.localStorage.setItem("deccanSalesUser", JSON.stringify(user))
  );
};

const getUserFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem("deccanSalesUser"));
};

const removeUserFromLocalStorage = () => {
  return window.localStorage.removeItem("deccanSalesUser");
};

export const loginUser = (credentials) => {
  return async (dispatch) => {
    const user = await authService.login(credentials);
    if (user) {
      saveUserToLocalStorage(user);
      dispatch(createNotification(`Login Successful`, false, 5));
      dispatch({
        type: "SAVE_USER",
        user,
      });
      setToken(user);
    } else {
      dispatch(createNotification(`Invalid Credentials`, true, 5));
    }
  };
};

export const getUser = () => {
  return (dispatch) => {
    const user = getUserFromLocalStorage();
    dispatch({
      type: "GET_USER",
      user,
    });
  };
};

export const clearUser = () => {
  return (dispatch) => {
    removeUserFromLocalStorage();
    dispatch({
      type: "GET_USER",
      user: null,
    });
    dispatch(createNotification(`Logout Successful`, false, 5));
  };
};

export const setToken = (token) => {
  authToken = `bearer ${token}`;
};

export const getToken = () => authToken;

export default authReducer;
