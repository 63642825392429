import axios from "axios";
const baseURL = "https://salesapp.deccanplast.com/api";

const getCalls = async (partnerID) => {
  try {
    const response = await axios.get(
      `${baseURL}/salescall/partner/${partnerID}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getDistributors = async (stateCode) => {
  try {
    const response = await axios.get(
      `${baseURL}/distributors/list/${stateCode}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getLocations = async (dist_code, state_code, customer_type) => {
  try {
    let response;
    if (customer_type === "1") {
      response = await axios.get(
        `${baseURL}/customers/find/location/${dist_code}`
      );
    } else {
      response = await axios.get(
        `${baseURL}/mhs_customers/find/location/state/${state_code}/district/${dist_code}`
      );
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

const getCustomers = async (
  stateCode,
  distributorCode,
  categoryCode,
  location
) => {
  try {
    const response = await axios.get(
      `${baseURL}/customers/all/${stateCode}/${distributorCode}/${categoryCode}/${location}`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getMHSCustomers = async (
  stateCode,
  districtCode,
  categoryCode,
  location
) => {
  try {
    const response = await axios.get(
      `${baseURL}/mhs_customers/list/state/${stateCode}/district/${districtCode}/category/${parseInt(
        categoryCode
      )}/location/${location}/all`
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const getProducts = async () => {
  try {
    const response = await axios.get(`${baseURL}/products`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const postFurnitureSalesCall = async (customer, cart, user) => {
  try {
    const response = await axios.post(`${baseURL}/salescall/pwa`, {
      customer,
      cart,
      user,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const postMHSSalesCall = async (customer, cart, user) => {
  try {
    const response = await axios.post(
      `${baseURL}/salescall/pwa_mhs_salescall`,
      {
        customer,
        cart,
        user,
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

const callService = {
  getCalls,
  getDistributors,
  getLocations,
  getCustomers,
  getMHSCustomers,
  getProducts,
  postFurnitureSalesCall,
  postMHSSalesCall,
};

export default callService;
