import React, { useState, useRef } from "react";

const Step4 = ({ goToPrevStep, goToNextStep, report, updateReport }) => {
  // Sales Qty
  const [compProducts, setcompProducts] = useState("");
  const [compPrices, setcompPrices] = useState("");
  const [compDiscounts, setcompDiscounts] = useState("");

  // Input Refs
  const comp_inputRef1 = useRef(null);
  const comp_inputRef2 = useRef(null);
  const comp_inputRef3 = useRef(null);

  // Functions
  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  // Validate all Inputs to check if they are empty
  const validateNextStep = () => {
    validateInput(comp_inputRef1);
    validateInput(comp_inputRef2);
    validateInput(comp_inputRef3);
    if (
      comp_inputRef1.current.value &&
      comp_inputRef2.current.value &&
      comp_inputRef3.current.value
    ) {
      // Update Report
      updateReport({
        ...report,
        competitor_activity: {
          new_competior_products: compProducts,
          competior_pricing: compPrices,
          discounts: compDiscounts,
        },
      });
      // Go to Next Step
      goToNextStep();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-purple-900">
          Competitor Activities
        </h3>
        <h6 className="text-sm font-bold text-purple-900">Step 4/5</h6>
      </div>
      <section>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Competitor Products</h5>
          <textarea
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
            placeholder="New Competitors products in the market"
            ref={comp_inputRef1}
            value={compProducts}
            onChange={(e) => setcompProducts(e.target.value)}
          ></textarea>
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Competitor Prices</h5>
          <textarea
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
            placeholder="Price details about the compatible products"
            ref={comp_inputRef2}
            value={compPrices}
            onChange={(e) => setcompPrices(e.target.value)}
          ></textarea>
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Discounts / Schemes</h5>
          <textarea
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
            placeholder="Discount Structure, New Schemes if any"
            ref={comp_inputRef3}
            value={compDiscounts}
            onChange={(e) => setcompDiscounts(e.target.value)}
          ></textarea>
        </div>
      </section>
      <p className="my-2 text-purple-900">
        <strong>Note:</strong> Please send above mentioned competitor product
        photos, prices, dimension, weight via whatsapp
      </p>
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step4;
