import React, { useState, useEffect } from "react";
import callService from "../../services/call";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";
import Spinner from "../utility/Spinner";

const LocationSpinner = () => {
  const [location, setLocation] = useState([]);
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customer_type = customer.customer_type;
  const dist_code =
    customer_type === "1" ? customer.distributor._id : customer.district_code;
  const state_code = customer.state_code;
  useEffect(() => {
    const fetchData = async (dist_code, state_code, customer_type) => {
      const results = await callService.getLocations(
        dist_code,
        state_code,
        customer_type
      );
      setLocation(results);
      if (!customer.location) {
        setCustomerLocation(results[0], "existing");
      }
    };
    fetchData(dist_code, state_code, customer_type);
  }, [dist_code, state_code, customer_type]);

  const setCustomerLocation = (location, location_type) => {
    const updatedCustomer = {
      ...customer,
      location,
      location_type,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  if (!location.length) {
    return <Spinner />;
  }
  return (
    <>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Select Location
      </h5>
      <select
        onChange={(e) => setCustomerLocation(e.target.value, "existing")}
        value={customer.location}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        {location.map((location, index) => (
          <option key={index} className="font-semibold" value={location}>
            {location}
          </option>
        ))}
      </select>
    </>
  );
};

export default LocationSpinner;
