import React, { useEffect } from "react";

import Home from "./components/home/Home";
import Calls from "./components/calls/Calls";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "./reducers/authReducer";
import CallDetails from "./components/calls/CallDetails";
import NewCall from "./components/calls/NewCall";
import Reports from "./components/reports/Reports";
import NewReport from "./components/reports/NewReport";
import ReportDetails from "./components/reports/ReportDetails";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  return (
    <Router>
      <div className="bg-yellow-100 min-h-screen">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/calls" component={Calls} />
          <Route exact path="/calldetails/:id" component={CallDetails} />
          <Route exact path="/newCall" component={NewCall} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/newreport" component={NewReport} />
          <Route exact path="/reportdetails/:id" component={ReportDetails} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
