import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Login from "../auth/Login";
import Navbar from "../navigation/Navbar";
import { Link } from "react-router-dom";

import { clearUser } from "../../reducers/authReducer";
import { hideNotification } from "../../reducers/notificationReducer";

const Home = () => {
  const dispatch = useDispatch();
  dispatch(hideNotification());
  const user = useSelector((state) => state.user);
  const handleLogout = () => {
    dispatch(clearUser());
  };

  if (!user) {
    return <Login />;
  }
  return (
    <>
      <Navbar title="Home" />
      <section className="mx-auto p-4">
        <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
          Welcome {user.name} !
        </h1>
        <div id="item_grid" className="grid grid-cols-2 gap-8 mt-16">
          <Link to="/calls">
            <div className="h-32 bg-blue-500 text-white text-2xl text-center uppercase flex justify-center items-center rounded-2xl shadow cursor-pointer">
              Calls
            </div>
          </Link>
          <Link to="/reports">
            <div className="h-32 bg-green-500 text-white text-2xl text-center uppercase flex justify-center items-center rounded-2xl shadow cursor-pointer">
              Reports
            </div>
          </Link>
          <Link to="/">
            <div className="h-32 bg-yellow-500 text-white text-2xl text-center uppercase flex justify-center items-center rounded-2xl shadow cursor-pointer">
              Profile
            </div>
          </Link>
          <div
            onClick={handleLogout}
            className="h-32 bg-red-500 text-white text-2xl text-center uppercase flex justify-center items-center rounded-2xl shadow cursor-pointer"
          >
            Logout
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
