import React from "react";
import Navbar from "../navigation/Navbar";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CallDetails = () => {
  const { id } = useParams();
  const currentCall = useSelector((state) =>
    state.calls.find((call) => call._id === id)
  );
  const showLoader = () => {
    return (
      <h3 className="text-xl text-purple-900 text-center my-8">Loading....</h3>
    );
  };

  const showCurrentCallDetails = (currentCall) => {
    const callDate = new Date(currentCall.created);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(callDate);

    const showOrderSummary = () => {
      return currentCall.order.items.map((item, index) => {
        return (
          <div className="bg-white my-1 p-4 shadow-lg">
            <p>
              Sl No: <span className="ml-2 font-bold">{index + 1}</span>
            </p>
            <p>
              Product:{" "}
              <span className="ml-2 font-bold">{item.product.title}</span>
            </p>
            <p>
              Quantity: <span className="ml-2 font-bold">{item.quantity}</span>
            </p>
            <p>
              Price:{" "}
              <span className="ml-2 font-bold">₹{item.product.price}</span>
            </p>
            <p>
              Item Total:{" "}
              <span className="ml-2 font-bold">
                ₹ {item.quantity * item.product.price}
              </span>
            </p>
          </div>
        );
      });
    };

    const calculateOrderValue = () => {
      let sum = 0;
      currentCall.order.items.map(
        (item) => (sum += item.quantity * item.product.price)
      );
      return sum;
    };

    return (
      <section>
        <div className="bg-pink-800 p-4 text-white">
          <h1 className="text-center text-2xl font-semibold uppercase">
            {currentCall.customer.organization}
          </h1>
          <h1 className="text-center text-sm mb-2">{`${currentCall.customer.location}, ${currentCall.customer.district}, ${currentCall.customer.state}`}</h1>
          <h3 className="text-center my-2">
            Submitted by {currentCall.partner.name}
          </h3>
          <h5 className="text-center my-2">Report as on {formattedDate}</h5>
        </div>
        <div className="my-4 p-4">
          <h2 className="text-center text-purple-900 text-xl font-semibold">
            Sales Call Details
          </h2>
          {currentCall.order ? (
            <>
              <h3 className="text-center text-xl font-bold my-2">
                Order Summary
              </h3>
              {showOrderSummary()}
              <h3 className="text-center text-xl font-bold my-4">
                Order Value: ₹{calculateOrderValue()}
              </h3>
            </>
          ) : (
            <h3 className="text-center text-xl font-bold my-2">No orders !</h3>
          )}
          <div className="my-4">
            <h3 className="text-center text-purple-900 text-xl font-semibold">
              Visit Details
            </h3>
            <p className="my-2">
              Contact Person: <br />
              <span className="text-lg">
                <strong>{currentCall.contact_person}</strong>
              </span>
            </p>
            <p className="my-2">
              Mobile: <br />
              <span className="text-lg">
                <strong>{currentCall.mobile}</strong>
              </span>
            </p>
            <p className="my-2">
              Target: <br />
              <span className="text-lg">
                <strong>₹{currentCall.customer.target}</strong>
              </span>
            </p>
            <p className="my-2">
              Feedback: <br />
              <span className="text-lg">
                <strong>{currentCall.feedback}</strong>
              </span>
            </p>
          </div>
        </div>
        <Link to="/calls">
          <button className="bg-purple-900 text-white py-2 px-4 block mx-auto uppercase rounded">
            Back
          </button>
        </Link>
      </section>
    );
  };

  return (
    <>
      <Navbar title="Call Details" />
      <section className="mx-auto pb-8">
        {currentCall ? showCurrentCallDetails(currentCall) : showLoader()}
      </section>
    </>
  );
};

export default CallDetails;
