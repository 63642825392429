import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

const Step5 = ({ goToNextStep, goToPrevStep, skipOrderStep }) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [feedback, setFeedback] = useState("");
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);

  useEffect(() => {
    customer.customer_details.name
      ? setName(customer.customer_details.name)
      : setName("");
    customer.customer_details.mobile
      ? setMobile(customer.customer_details.mobile)
      : setMobile("");
  }, [customer]);

  const updateCustomerName = (customer_name) => {
    const customer_details = {
      ...customer.customer_details,
      name: customer_name,
    };

    const updatedCustomer = {
      ...customer,
      customer_details,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const updateCustomerMobile = (customer_mobile) => {
    const customer_details = {
      ...customer.customer_details,
      mobile: customer_mobile,
    };

    const updatedCustomer = {
      ...customer,
      customer_details,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const updateCustomerFeedback = (customer_feedback) => {
    setFeedback(customer_feedback);
    const customer_details = {
      ...customer.customer_details,
      feedback: customer_feedback,
    };

    const updatedCustomer = {
      ...customer,
      customer_details,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  const validateNextStep = () => {
    validateInput(inputRef1);
    validateInput(inputRef2);
    validateInput(inputRef3);
    if (
      inputRef1.current.value &&
      inputRef2.current.value &&
      inputRef3.current.value
    ) {
      goToNextStep();
    }
  };

  return (
    <div>
      <h3 className="text-center text-xl my-2 text-purple-900">
        Customer Information
      </h3>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Contact Person
      </h5>
      <input
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        type="text"
        value={name}
        ref={inputRef1}
        onChange={(e) => updateCustomerName(e.target.value)}
        placeholder="Contact Person Name"
      />
      <h5 className="text-center text-lg my-2 text-purple-900">Mobile</h5>
      <input
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        type="number"
        value={mobile}
        ref={inputRef2}
        onChange={(e) => updateCustomerMobile(e.target.value)}
        placeholder="Contact Person Mobile"
      />
      <h5 className="text-center text-lg my-2 text-purple-900">
        Customer Feedback
      </h5>
      <input
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        type="text"
        value={feedback}
        ref={inputRef3}
        onChange={(e) => updateCustomerFeedback(e.target.value)}
        placeholder="Customer Feedback"
      />
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step5;
