import reportService from "../services/report";

const reportReducer = (state = [], action) => {
  switch (action.type) {
    case "NEW_REPORT":
      return [...state, action.data];
    case "INIT_REPORTS":
      return action.data;
    default:
      return state;
  }
};

export const getReports = async (partnerID) => {
  return async (dispatch) => {
    const reports = await reportService.getReports(partnerID);
    dispatch({
      type: "INIT_REPORTS",
      data: reports,
    });
  };
};

export const initreports = (reports = []) => {
  return async (dispatch) => {
    dispatch({
      type: "INIT_REPORTS",
      data: reports,
    });
  };
};

export default reportReducer;
