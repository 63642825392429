import React, { useState, useEffect } from "react";
import callService from "../../services/call";
import Cart from "./Cart";
import Category from "./Category";

const Step6 = ({ goToNextStep, goToPrevStep }) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const results = await callService.getProducts();
      setProducts(groupByKey(results, "category"));
    };
    fetchData();
  }, []);

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, []);
  }
  return (
    <div>
      <Cart goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
      <h3 className="text-center text-xl my-2 text-purple-900">
        Select Products
      </h3>
      {Object.keys(products).map((category, index) => (
        <Category
          key={index}
          category={category}
          products={products[category]}
        />
      ))}
    </div>
  );
};

export default Step6;
