import axios from "axios";
const loginURL = "https://salesapp.deccanplast.com/api/auth";

const login = async (credentials) => {
  try {
    const response = await axios.post(loginURL, {
      username: credentials.username,
      passpin: credentials.passpin,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const authService = { login };

export default authService;
