import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

import { furniture_categories, mhs_categories } from "../../helper";
import Spinner from "../utility/Spinner";

const CategorySpinner = ({ stateCode }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const [categoryCode, setCategoryCode] = useState(
    customer.category_code ? customer.category_code : "2"
  );

  const categories =
    customer.customer_type === "1" ? furniture_categories : mhs_categories;

  useEffect(() => {
    const category_details = categories.find(
      (cat) => cat.code === parseInt(categoryCode)
    );

    const category = category_details.name;

    const updatedCustomer = {
      ...customer,
      category,
      category_code: categoryCode,
    };
    dispatch(updateCustomer(updatedCustomer));
  }, [categoryCode]);

  if (!categories.length) {
    return <Spinner />;
  }
  return (
    <>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Select Category
      </h5>
      <select
        onChange={(e) => setCategoryCode(e.target.value)}
        value={categoryCode}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        {categories.map((category, index) => (
          <option
            key={`${index}`}
            className="font-semibold"
            value={category.code}
          >
            {category.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default CategorySpinner;
