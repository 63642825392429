import React, { useState, useRef } from "react";

const Step3 = ({ goToPrevStep, goToNextStep, report, updateReport }) => {
  // State
  // Sales Qty
  const [deccanSalesQty, setdeccanSalesQty] = useState("");
  const [nilkamalSalesQty, setnilkamalSalesQty] = useState("");
  const [primaSalesQty, setprimaSalesQty] = useState("");
  const [kissanSalesQty, setkissanSalesQty] = useState("");
  const [nationalSalesQty, setnationalSalesQty] = useState("");
  const [othersSalesQty, setothersSalesQty] = useState("");
  // Percentage Market Share
  const [deccanPMS, setdeccanPMS] = useState("");
  const [nilkamalPMS, setnilkamalPMS] = useState("");
  const [primaPMS, setprimaPMS] = useState("");
  const [kissanPMS, setkissanPMS] = useState("");
  const [nationalPMS, setnationalPMS] = useState("");
  const [othersPMS, setothersPMS] = useState("");

  // Input Refs
  const ms_inputRef1 = useRef(null);
  const ms_inputRef2 = useRef(null);
  const ms_inputRef3 = useRef(null);
  const ms_inputRef4 = useRef(null);
  const ms_inputRef5 = useRef(null);
  const ms_inputRef6 = useRef(null);
  const ms_inputRef7 = useRef(null);
  const ms_inputRef8 = useRef(null);
  const ms_inputRef9 = useRef(null);
  const ms_inputRef10 = useRef(null);
  const ms_inputRef11 = useRef(null);
  const ms_inputRef12 = useRef(null);

  // Functions
  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  // Validate all Inputs to check if they are empty
  const validateNextStep = () => {
    validateInput(ms_inputRef1);
    validateInput(ms_inputRef2);
    validateInput(ms_inputRef3);
    validateInput(ms_inputRef4);
    validateInput(ms_inputRef5);
    validateInput(ms_inputRef6);
    validateInput(ms_inputRef7);
    validateInput(ms_inputRef8);
    validateInput(ms_inputRef9);
    validateInput(ms_inputRef10);
    validateInput(ms_inputRef11);
    validateInput(ms_inputRef12);
    if (
      ms_inputRef1.current.value &&
      ms_inputRef2.current.value &&
      ms_inputRef3.current.value &&
      ms_inputRef4.current.value &&
      ms_inputRef5.current.value &&
      ms_inputRef6.current.value &&
      ms_inputRef7.current.value &&
      ms_inputRef8.current.value &&
      ms_inputRef9.current.value &&
      ms_inputRef10.current.value &&
      ms_inputRef11.current.value &&
      ms_inputRef12.current.value
    ) {
      // Update Report
      updateReport({
        ...report,
        market_share: {
          deccan: {
            sales_qty: deccanSalesQty,
            sales_percentage: deccanPMS,
          },
          nilkamal: {
            sales_qty: nilkamalSalesQty,
            sales_percentage: nilkamalPMS,
          },
          prima: {
            sales_qty: primaSalesQty,
            sales_percentage: primaPMS,
          },
          kissan: {
            sales_qty: kissanSalesQty,
            sales_percentage: kissanPMS,
          },
          national: {
            sales_qty: nationalSalesQty,
            sales_percentage: nationalPMS,
          },
          others: {
            sales_qty: othersSalesQty,
            sales_percentage: othersPMS,
          },
        },
      });
      // Go to Next Step
      goToNextStep();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-purple-900">Market Share</h3>
        <h6 className="text-sm font-bold text-purple-900">Step 3/5</h6>
      </div>
      <section>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Approx Brandwise Sales Qty in the Area (in Nos)
          </h5>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Deccan
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef1}
              value={deccanSalesQty}
              onChange={(e) => setdeccanSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Nilkamal
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef2}
              value={nilkamalSalesQty}
              onChange={(e) => setnilkamalSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Prima
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef3}
              value={primaSalesQty}
              onChange={(e) => setprimaSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Kissan
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef4}
              value={kissanSalesQty}
              onChange={(e) => setkissanSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              National
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef5}
              value={nationalSalesQty}
              onChange={(e) => setnationalSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Others
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef6}
              value={othersSalesQty}
              onChange={(e) => setothersSalesQty(e.target.value)}
              placeholder="Add value in No's"
            />
          </div>
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Approx Brandwise Market Share (in %)
          </h5>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Deccan
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef7}
              value={deccanPMS}
              onChange={(e) => setdeccanPMS(e.target.value)}
              placeholder="Add value in %,  0 to 100"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Nilkamal
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef8}
              value={nilkamalPMS}
              onChange={(e) => setnilkamalPMS(e.target.value)}
              placeholder="Add value in %,  0 to 100"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Prima
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              ref={ms_inputRef9}
              value={primaPMS}
              onChange={(e) => setprimaPMS(e.target.value)}
              placeholder="Add value in %,  0 to 100"
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Kissan
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              placeholder="Add value in %,  0 to 100"
              ref={ms_inputRef10}
              value={kissanPMS}
              onChange={(e) => setkissanPMS(e.target.value)}
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              National
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              placeholder="Add value in %,  0 to 100"
              ref={ms_inputRef11}
              value={nationalPMS}
              onChange={(e) => setnationalPMS(e.target.value)}
            />
          </div>
          <div className="my-4 flex justify-between items-center">
            <label
              className="font-semibold text-purple-900 mr-4"
              htmlFor="deccan_market_share"
            >
              Others
            </label>
            <input
              type="number"
              className="px-2 py-2 w-3/4 rounded-lg font-semibold"
              placeholder="Add value in %,  0 to 100"
              ref={ms_inputRef12}
              value={othersPMS}
              onChange={(e) => setothersPMS(e.target.value)}
            />
          </div>
        </div>
      </section>
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step3;
