import React, { useState, useRef } from "react";

const Step5 = ({ goToPrevStep, submitReport, report, updateReport }) => {
  // Sales Qty

  const [actionsRequired, setactionsRequired] = useState([]);
  const [suggestions, setsuggestions] = useState("");

  // Input Refs
  const fb_inputRef1 = useRef(null);

  const handleCheckBox = (e, item) => {
    if (e.target.checked) {
      setactionsRequired([...actionsRequired, item]);
    } else {
      setactionsRequired(actionsRequired.filter((i) => i !== item));
    }
  };

  // Functions
  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  // Validate all Inputs to check if they are empty
  const validateNextStep = () => {
    console.log("validate next step");
    validateInput(fb_inputRef1);
    if (fb_inputRef1.current.value) {
      // Update Report
      updateReport({
        ...report,
        market_feedback: {
          actions_required: actionsRequired,
          suggestions,
        },
      });
      // Go to Next Step
      submitReport();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-purple-900">Market Feedback</h3>
        <h6 className="text-sm font-bold text-purple-900">Step 5/5</h6>
      </div>
      <section>
        <div className="mt-4 mb-4">
          <h5 className="text-lg mb-1 text-purple-900">
            Actions Required (Tick Applicable)
          </h5>
          <div className="my-1">
            <input
              className="mr-2"
              type="checkbox"
              name=""
              onChange={(e) => handleCheckBox(e, "More Visits")}
              id="more_visit_checkbox"
            />
            <label
              className="font-semibold text-purple-900"
              htmlFor="more_visit_checkbox"
            >
              More Visits
            </label>
          </div>
          <div className="my-1">
            <input
              className="mr-2"
              type="checkbox"
              name=""
              onChange={(e) => handleCheckBox(e, "More Dealers")}
              id="more_dealers_checkbox"
            />
            <label
              className="font-semibold text-purple-900"
              htmlFor="more_dealers_checkbox"
            >
              More Dealers
            </label>
          </div>
          <div className="my-1">
            <input
              className="mr-2"
              type="checkbox"
              name=""
              onChange={(e) => handleCheckBox(e, "More Direct Buyers")}
              id="more_direct_buyers_checkbox"
            />
            <label
              className="font-semibold text-purple-900"
              htmlFor="more_direct_buyers_checkbox"
            >
              More Direct Buyers
            </label>
          </div>
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Suggestions / Inputs</h5>
          <textarea
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
            placeholder="Inputs required for this markets for your suggestions"
            ref={fb_inputRef1}
            value={suggestions}
            onChange={(e) => setsuggestions(e.target.value)}
          ></textarea>
        </div>
      </section>
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-green-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Step5;
