import React from "react";
import Navbar from "../navigation/Navbar";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportDetails = () => {
  const { id } = useParams();
  const currentReport = useSelector((state) =>
    state.reports.find((report) => report._id === id)
  );
  const showLoader = () => {
    return (
      <h3 className="text-xl text-purple-900 text-center my-8">Loading....</h3>
    );
  };

  const showCurrentReportDetails = (currentReport) => {
    const reportDate = new Date(currentReport.created);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(reportDate);

    return (
      <section>
        <div className="bg-pink-800 p-4 text-white">
          <h1 className="text-center text-2xl font-semibold uppercase">
            {currentReport.bp.bp_name}, {currentReport.bp.bp_market}
          </h1>
          <h3 className="text-center my-2">
            Submitted by {currentReport.partner.name}
          </h3>
          <h5 className="text-center my-2">Report as on {formattedDate}</h5>
        </div>
        {/* BP Details */}
        <div className="my-4 p-4">
          <h2 className="text-center text-purple-900 text-xl font-semibold">
            Sales Report Details
          </h2>
          <div className="my-4">
            <h3 className="text-center text-purple-900 text-xl font-semibold">
              BP Details
            </h3>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Name: <br />
                <span className="text-lg">
                  <strong>{currentReport.bp.bp_name}</strong>
                </span>
              </p>
              <p className="my-2">
                Location: <br />
                <span className="text-lg">
                  <strong>{currentReport.bp.bp_location}</strong>
                </span>
              </p>
              <p className="my-2">
                Market: <br />
                <span className="text-lg">
                  <strong>{currentReport.bp.bp_market}</strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Monthly Sales Target: <br />
                <span className="text-lg">
                  <strong>₹{currentReport.bp.bp_monthly_sales_target}</strong>
                </span>
              </p>
              <p className="my-2">
                Stock Value: <br />
                <span className="text-lg">
                  <strong>₹{currentReport.bp.bp_stock_value}</strong>
                </span>
              </p>
              <p className="my-2">
                Stock Qty: <br />
                <span className="text-lg">
                  <strong>{currentReport.bp.bp_stock_qty}</strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Dealer Recievable: <br />
                <span className="text-lg">
                  <strong>₹{currentReport.bp.bp_recievable}</strong>
                </span>
              </p>
              <p className="my-2">
                Joint Sales Target: <br />
                <span className="text-lg">
                  <strong>₹{currentReport.bp.bp_deccan_joint_sales}</strong>
                </span>
              </p>
            </div>
          </div>
          {/* Sales Call Details */}
          <div className="my-4">
            <h2 className="text-center text-purple-900 text-xl font-semibold">
              Sales Call Details
            </h2>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Today Sales Calls: <br />
                <span className="text-lg">
                  <strong>{currentReport.calls.today_sales_calls}</strong>
                </span>
              </p>
              <p className="my-2">
                Monthly Cumulative: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.calls.monthly_cumulative_sales_calls}
                  </strong>
                </span>
              </p>
            </div>
            <h3 className="font-bold text-purple-900">Today Visits</h3>
            <div className="flex justify-between items-center">
              <p className="my-2">
                A Class Dealers: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.calls.today_a_class_dealer_visits}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                A Class Direct Buyers: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.calls.today_a_class_direct_buyer_visits}
                  </strong>
                </span>
              </p>
            </div>
            <h3 className="font-bold text-purple-900">Today Orders</h3>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Premium Products: <br />
                <span className="text-lg">
                  <strong>
                    ₹{currentReport.calls.today_premium_product_orders}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Other Products: <br />
                <span className="text-lg">
                  <strong>
                    ₹{currentReport.calls.today_other_product_orders}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Secondary Orders: <br />
                <span className="text-lg">
                  <strong>₹{currentReport.calls.today_secondary_orders}</strong>
                </span>
              </p>
            </div>
            <h3 className="font-bold text-purple-900">
              Monthly Cumulative Orders
            </h3>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Premium Products: <br />
                <span className="text-lg">
                  <strong>
                    ₹
                    {
                      currentReport.calls
                        .monthly_cumulative_premium_product_orders
                    }
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Other Products: <br />
                <span className="text-lg">
                  <strong>
                    ₹
                    {
                      currentReport.calls
                        .monthly_cumulative_other_product_orders
                    }
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Secondary Orders: <br />
                <span className="text-lg">
                  <strong>
                    ₹{currentReport.calls.monthly_cumulative_secondary_orders}
                  </strong>
                </span>
              </p>
            </div>
          </div>
          {/* Market Share */}
          <div className="my-4">
            <h2 className="text-center text-purple-900 text-xl font-semibold">
              Market Share
            </h2>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Deccan Sales Qty: <br />
                <span className="text-lg">
                  <strong>{currentReport.market_share.deccan.sales_qty}</strong>
                </span>
              </p>
              <p className="my-2">
                Deccan Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.deccan.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Nilkamal Sales Qty: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.nilkamal.sales_qty}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Nilkamal Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.nilkamal.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Prima Sales Qty: <br />
                <span className="text-lg">
                  <strong>{currentReport.market_share.prima.sales_qty}</strong>
                </span>
              </p>
              <p className="my-2">
                Prima Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.prima.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Kissan Sales Qty: <br />
                <span className="text-lg">
                  <strong>{currentReport.market_share.kissan.sales_qty}</strong>
                </span>
              </p>
              <p className="my-2">
                Kissan Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.kissan.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                National Sales Qty: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.national.sales_qty}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                National Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.national.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p className="my-2">
                Others Sales Qty: <br />
                <span className="text-lg">
                  <strong>{currentReport.market_share.others.sales_qty}</strong>
                </span>
              </p>
              <p className="my-2">
                Others Sales %: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_share.others.sales_percentage}
                  </strong>
                </span>
              </p>
            </div>
          </div>
          {/* Competitor Analysis */}
          <div className="my-4">
            <h2 className="text-center text-purple-900 text-xl font-semibold">
              Competitor Actvities
            </h2>
            <div>
              <p className="my-2">
                New Competitor Products: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.competitor_activity.new_competior_products}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Competior Pricing: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.competitor_activity.competior_pricing}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Discounts: <br />
                <span className="text-lg">
                  <strong>{currentReport.competitor_activity.discounts}</strong>
                </span>
              </p>
            </div>
          </div>
          {/* Feedback Suggestion */}
          <div className="my-4">
            <h2 className="text-center text-purple-900 text-xl font-semibold">
              Market Feedback
            </h2>
            <div>
              <p className="my-2">
                Action Required: <br />
                <span className="text-lg">
                  <strong>
                    {currentReport.market_feedback.actions_required}
                  </strong>
                </span>
              </p>
              <p className="my-2">
                Suggestions: <br />
                <span className="text-lg">
                  <strong>{currentReport.market_feedback.suggestions}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
        <Link to="/reports">
          <button className="bg-purple-900 text-white py-2 px-4 block mx-auto uppercase rounded">
            Back
          </button>
        </Link>
      </section>
    );
  };

  return (
    <>
      <Navbar title="Report Details" />
      <section className="mx-auto pb-8">
        {currentReport ? showCurrentReportDetails(currentReport) : showLoader()}
      </section>
    </>
  );
};

export default ReportDetails;
