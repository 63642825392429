import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../reducers/cartReducer";

const Product = ({ product }) => {
  const cart = useSelector((state) => state.cart);
  const productPresent = cart.find((item) => item.product._id === product._id);
  let productIndex = cart.indexOf(productPresent);
  const [quantity, setQuantity] = useState(
    productPresent ? cart[productIndex].quantity : 0
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (quantity > 0) {
      if (productPresent) {
        cart[productIndex].quantity = quantity;
        let updatedCart = [...cart];
        dispatch(updateCart(updatedCart));
      } else {
        let updatedCart = [...cart, { product, quantity }];
        dispatch(updateCart(updatedCart));
      }
    } else {
      if (productPresent) {
        let updatedCart = cart.filter(
          (item) => item.product._id !== cart[productIndex].product._id
        );
        dispatch(updateCart(updatedCart));
      }
    }
  }, [quantity]);

  return (
    <div className="flex justify-between p-2 mb-1 border-b shadow">
      <div>
        <h5 className="font-semibold">{product.title}</h5>
        <p>Rate: ₹ {product.price} </p>
      </div>
      <input
        className="block w-8 text-center font-semibold border-b border-gray-500 text-black text-xl"
        type="number"
        value={quantity}
        onChange={(e) => setQuantity(parseInt(e.target.value))}
      />
    </div>
  );
};

export default Product;
