import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";
import LocationSpinner from "./LocationSpinner";

const Step3 = ({ goToNextStep, goToPrevStep }) => {
  const [currentLocation, setCurrentLocation] = useState("existing");
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const newLocationInput = () => {
    return (
      <>
        <h5 className="text-center text-lg my-2 text-purple-900">
          Enter New Location Name
        </h5>
        <input
          onChange={(e) =>
            updateCustomerLocation(e.target.value, currentLocation)
          }
          type="text"
          placeholder="Enter name of the location"
          className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        />
      </>
    );
  };

  const updateCustomerLocation = (location, location_type) => {
    const updatedCustomer = {
      ...customer,
      location,
      location_type,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  return (
    <div>
      <h3 className="text-center text-xl my-2 text-purple-900">
        Customer Location
      </h3>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Type of Location
      </h5>
      <select
        onChange={(e) => setCurrentLocation(e.target.value)}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        <option className="font-semibold" value="existing">
          Existing
        </option>
        <option className="font-semibold" value="new">
          New
        </option>
      </select>
      {currentLocation === "existing" ? (
        <LocationSpinner />
      ) : (
        newLocationInput()
      )}
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={goToNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step3;
