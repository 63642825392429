import React from "react";
import { useSelector } from "react-redux";

const Cart = ({ goToNextStep, goToPrevStep }) => {
  const cart = useSelector((state) => state.cart);
  const showCartDetails = () => {
    const total = cart.reduce(
      (sum, item) => item.product.price * item.quantity + sum,
      0
    );
    return (
      <ul>
        <li className="bg-white shadow p-2 font-semibold flex justify-between border-b border-gray-500">
          <span>Product</span> <span>Qty</span>
        </li>
        {cart.map((item) => (
          <li className="bg-white shadow p-2 font-semibold flex justify-between">
            <span>
              {item.product.title} (
              <span className="text-sm">₹ {item.product.price}</span>)
            </span>
            <span>{item.quantity}</span>
          </li>
        ))}
        <li className="bg-white shadow p-2 font-semibold flex justify-between mt-2">
          <span>Cart Value</span> <span>₹ {total}</span>
        </li>
      </ul>
    );
  };
  const showEmptyCart = () => (
    <p className="font-semibold text-center">Cart is Empty !</p>
  );
  return (
    <div className="my-4">
      <h3 className="text-xl text-center my-4">Cart Details</h3>
      {cart.length ? showCartDetails() : showEmptyCart()}
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={goToNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          {cart.length ? "Next" : "Skip"}
        </button>
      </div>
      <h3 className="text-lg text-center my-4">(Skip if no order...)</h3>
    </div>
  );
};

export default Cart;
