import React, { useState, useRef } from "react";

const Step2 = ({ goToPrevStep, goToNextStep, report, updateReport }) => {
  // State
  const [todaySalesCalls, settodaySalesCalls] = useState("");
  const [monthlyCumulatveSalesCalls, setmonthlyCumulatveSalesCalls] =
    useState("");
  const [aClassDealerVisited, setaClassDealerVisited] = useState("");
  const [aClassDirectBuyerVisited, setaClassDirectBuyerVisited] = useState("");
  const [todayPremiumProductOrders, settodayPremiumProductOrders] =
    useState("");
  const [todayOtherProductOrders, settodayOtherProductOrders] = useState("");
  const [todaySecondaryOrders, settodaySecondaryOrders] = useState("");
  const [
    monthlyCumulatvePremiumProductOrders,
    setmonthlyCumulatvePremiumProductOrders,
  ] = useState("");
  const [
    monthlyCumulatveOtherProductOrders,
    setmonthlyCumulatveOtherProductOrders,
  ] = useState("");
  const [monthlyCumulatveSecondaryOrders, setmonthlyCumulatveSecondaryOrders] =
    useState("");

  // Input Refs
  const sales_inputRef1 = useRef(null);
  const sales_inputRef2 = useRef(null);
  const sales_inputRef3 = useRef(null);
  const sales_inputRef4 = useRef(null);
  const sales_inputRef5 = useRef(null);
  const sales_inputRef6 = useRef(null);
  const sales_inputRef7 = useRef(null);
  const sales_inputRef8 = useRef(null);
  const sales_inputRef9 = useRef(null);
  const sales_inputRef10 = useRef(null);

  // Functions
  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  // Validate all Inputs to check if they are empty
  const validateNextStep = () => {
    validateInput(sales_inputRef1);
    validateInput(sales_inputRef2);
    validateInput(sales_inputRef3);
    validateInput(sales_inputRef4);
    validateInput(sales_inputRef5);
    validateInput(sales_inputRef6);
    validateInput(sales_inputRef7);
    validateInput(sales_inputRef8);
    validateInput(sales_inputRef9);
    validateInput(sales_inputRef10);
    if (
      sales_inputRef1.current.value &&
      sales_inputRef2.current.value &&
      sales_inputRef3.current.value &&
      sales_inputRef4.current.value &&
      sales_inputRef5.current.value &&
      sales_inputRef6.current.value &&
      sales_inputRef7.current.value &&
      sales_inputRef8.current.value &&
      sales_inputRef9.current.value &&
      sales_inputRef10.current.value
    ) {
      // Update Report
      updateReport({
        ...report,
        calls: {
          today_sales_calls: todaySalesCalls,
          monthly_cumulative_sales_calls: monthlyCumulatveSalesCalls,
          today_a_class_dealer_visits: aClassDealerVisited,
          today_a_class_direct_buyer_visits: aClassDirectBuyerVisited,
          today_premium_product_orders: todayPremiumProductOrders,
          today_other_product_orders: todayOtherProductOrders,
          today_secondary_orders: todaySecondaryOrders,
          monthly_cumulative_premium_product_orders:
            monthlyCumulatvePremiumProductOrders,
          monthly_cumulative_other_product_orders:
            monthlyCumulatveOtherProductOrders,
          monthly_cumulative_secondary_orders: monthlyCumulatveSecondaryOrders,
        },
      });
      // Go to Next Step
      goToNextStep();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-purple-900">
          Sales Call Details
        </h3>
        <h6 className="text-sm font-bold text-purple-900">Step 2/5</h6>
      </div>
      <section>
        <h5 className="my-4 text-lg text-purple-900 font-bold">
          Daily Calls Summary
        </h5>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Sales Calls Today (in No's)
          </h5>
          <input
            type="number"
            placeholder="Min. 15 calls per day"
            ref={sales_inputRef1}
            value={todaySalesCalls}
            onChange={(e) => settodaySalesCalls(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Total Cumulative Call this Month (in No's)
          </h5>
          <input
            type="number"
            placeholder="Cumulative Calls in the Month"
            ref={sales_inputRef2}
            value={monthlyCumulatveSalesCalls}
            onChange={(e) => setmonthlyCumulatveSalesCalls(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            "A" class <strong>Dealers</strong> Visited Today (in No's)
          </h5>
          <input
            type="number"
            placeholder="Total no. of visits"
            ref={sales_inputRef3}
            value={aClassDealerVisited}
            onChange={(e) => setaClassDealerVisited(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            "A" class <strong>Direct Buyers</strong> Visited Today (in No's)
          </h5>
          <input
            type="number"
            ref={sales_inputRef4}
            value={aClassDirectBuyerVisited}
            onChange={(e) => setaClassDirectBuyerVisited(e.target.value)}
            placeholder="Total no. of visits"
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <h5 className="my-4 text-lg text-purple-900 font-bold">
          Daily Order Summary
        </h5>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Premium Product Orders (in ₹)
          </h5>
          <input
            type="number"
            placeholder="Orders for premium products in ₹"
            ref={sales_inputRef5}
            value={todayPremiumProductOrders}
            onChange={(e) => settodayPremiumProductOrders(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Other Product Orders (in ₹)
          </h5>
          <input
            type="number"
            placeholder="Orders for other products in ₹"
            ref={sales_inputRef6}
            value={todayOtherProductOrders}
            onChange={(e) => settodayOtherProductOrders(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Secondary Orders (in ₹)
          </h5>
          <input
            type="number"
            placeholder="Total secondary orders for the day ₹"
            ref={sales_inputRef7}
            value={todaySecondaryOrders}
            onChange={(e) => settodaySecondaryOrders(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <h5 className="my-4 text-lg text-purple-900 font-bold">
          Monthly Order Summary
        </h5>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Monthly Total Cumulative Premium Product Orders (in Lakh ₹)
          </h5>
          <input
            type="number"
            placeholder="Orders in lakh ₹"
            ref={sales_inputRef8}
            value={monthlyCumulatvePremiumProductOrders}
            onChange={(e) =>
              setmonthlyCumulatvePremiumProductOrders(e.target.value)
            }
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Monthly Total Cumulative Other Product Orders (in Lakh ₹)
          </h5>
          <input
            type="number"
            placeholder="Orders in lakh ₹"
            ref={sales_inputRef9}
            value={monthlyCumulatveOtherProductOrders}
            onChange={(e) =>
              setmonthlyCumulatveOtherProductOrders(e.target.value)
            }
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Monthly Total Cumulative Secondary Orders (in Lakh ₹)
          </h5>
          <input
            type="number"
            placeholder="Orders in lakh ₹"
            ref={sales_inputRef10}
            value={monthlyCumulatveSecondaryOrders}
            onChange={(e) => setmonthlyCumulatveSecondaryOrders(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
      </section>
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2;
