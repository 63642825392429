import React, { useState, useRef } from "react";

const Step1 = ({ cancelReport, goToNextStep, report, updateReport }) => {
  // State
  const [bpMarket, setbpMarket] = useState("");
  const [bpName, setbpName] = useState("");
  const [bpLocation, setbpLocation] = useState("");
  const [bpMonthlySalesTarget, setbpMonthlySalesTarget] = useState("");
  const [bpStockValue, setbpStockValue] = useState("");
  const [bpStockQty, setbpStockQty] = useState("");
  const [bpRecievable, setbpRecievable] = useState("");
  const [bpDeccanJointSales, setbpDeccanJointSales] = useState("");
  // Input Refs
  const bp_inputRef1 = useRef(null);
  const bp_inputRef2 = useRef(null);
  const bp_inputRef3 = useRef(null);
  const bp_inputRef4 = useRef(null);
  const bp_inputRef5 = useRef(null);
  const bp_inputRef6 = useRef(null);
  const bp_inputRef7 = useRef(null);
  const bp_inputRef8 = useRef(null);
  // Functions
  const validateInput = (inputRef) => {
    if (!inputRef.current.value) {
      inputRef.current.classList.add("border-4");
      inputRef.current.classList.add("border-red-500");
    }
  };

  // Validate all Inputs to check if they are empty
  const validateNextStep = () => {
    validateInput(bp_inputRef1);
    validateInput(bp_inputRef2);
    validateInput(bp_inputRef3);
    validateInput(bp_inputRef4);
    validateInput(bp_inputRef5);
    validateInput(bp_inputRef6);
    validateInput(bp_inputRef7);
    validateInput(bp_inputRef8);
    if (
      bp_inputRef1.current.value &&
      bp_inputRef2.current.value &&
      bp_inputRef3.current.value &&
      bp_inputRef4.current.value &&
      bp_inputRef5.current.value &&
      bp_inputRef6.current.value &&
      bp_inputRef7.current.value &&
      bp_inputRef8.current.value
    ) {
      // Update Report
      updateReport({
        ...report,
        bp: {
          bp_market: bpMarket,
          bp_name: bpName,
          bp_location: bpLocation,
          bp_monthly_sales_target: bpMonthlySalesTarget,
          bp_stock_value: bpStockValue,
          bp_stock_qty: bpStockQty,
          bp_recievable: bpRecievable,
          bp_deccan_joint_sales: bpDeccanJointSales,
        },
      });
      // Go to Next Step
      goToNextStep();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-purple-900">BP Details</h3>
        <h6 className="text-sm font-bold text-purple-900">Step 1/5</h6>
      </div>
      <section>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Market Worked</h5>
          <input
            type="text"
            placeholder="Name of the Area"
            ref={bp_inputRef1}
            value={bpMarket}
            onChange={(e) => setbpMarket(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Name of the BP</h5>
          <input
            type="text"
            placeholder="BP Name"
            ref={bp_inputRef2}
            value={bpName}
            onChange={(e) => setbpName(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">Location of the BP</h5>
          <input
            type="text"
            placeholder="BP Location"
            ref={bp_inputRef3}
            value={bpLocation}
            onChange={(e) => setbpLocation(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            BP Monthly Sales Target in Lakh ₹
          </h5>
          <input
            type="number"
            placeholder="Sales Target in Lakh ₹"
            ref={bp_inputRef4}
            value={bpMonthlySalesTarget}
            onChange={(e) => setbpMonthlySalesTarget(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            BP Stock Approx Value in ₹
          </h5>
          <input
            type="number"
            placeholder="BP Stock Value in ₹"
            ref={bp_inputRef5}
            value={bpStockValue}
            onChange={(e) => setbpStockValue(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            BP Stock Approx Qty (in No's)
          </h5>
          <input
            type="number"
            placeholder="BP Stock Qty in No's"
            ref={bp_inputRef6}
            value={bpStockQty}
            onChange={(e) => setbpStockQty(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            BP Recievables from Dealers in ₹
          </h5>
          <input
            type="number"
            placeholder="Dealer Recievables in ₹"
            ref={bp_inputRef7}
            value={bpRecievable}
            onChange={(e) => setbpRecievable(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
        <div className="mt-4 mb-2">
          <h5 className="text-lg mb-1 text-purple-900">
            Deccan Targeted Sales Jointly Agreed by BP
          </h5>
          <input
            type="number"
            placeholder="Target Sales in ₹"
            ref={bp_inputRef8}
            value={bpDeccanJointSales}
            onChange={(e) => setbpDeccanJointSales(e.target.value)}
            className="my-1 px-4 py-3 rounded-lg w-full font-semibold"
          />
        </div>
      </section>
      <div className="my-8 flex justify-around">
        <button
          onClick={cancelReport}
          className="bg-red-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Cancel
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1;
