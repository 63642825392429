import React from "react";

const FloatingButton = ({ handleClick }) => {
  return (
    <button
      onClick={handleClick}
      className="fixed bottom-4 right-4 bg-pink-600 w-16 h-16 rounded-full border-4 border-pink-900 z-50 shadow-lg cursor-pointer focus:outline-none "
    >
      <svg
        className="w-8 text-white mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
    </button>
  );
};

export default FloatingButton;
