import React from "react";
import DistributorSpinner from "./DistributorSpinner";
import DistrictSpinner from "./DistrictSpinner";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

// Helper

const Step2 = ({ goToNextStep, goToPrevStep }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = customer.customer_type;
  const customerState = customer.state_code;

  const changeCustomerState = (code) => {
    const updatedCustomer = {
      ...customer,
      state_code: code,
    };

    dispatch(updateCustomer(updatedCustomer));
  };

  const getCustomerType = (code) => {
    switch (code) {
      case "1":
        return "Furniture Customer";
      case "2":
        return "MHS Customer";
      default:
        return "Furniture Customer";
    }
  };

  const getDistSpinner = (code) => {
    switch (code) {
      case "1":
        return loadDistributorSpinner();
      case "2":
        return loadDistrictSpinner();
      default:
        return loadDistributorSpinner();
    }
  };

  const loadDistributorSpinner = () => {
    return <DistributorSpinner stateCode={customerState} />;
  };

  const loadDistrictSpinner = () => {
    return <DistrictSpinner stateCode={customerState} />;
  };

  return (
    <div>
      <h3 className="text-center text-xl my-2 text-purple-900">
        Enter Customer Details
      </h3>
      <h6 className="text-center my-2">({getCustomerType(customerType)})</h6>
      {/* State */}
      <h5 className="text-center text-lg my-2 text-purple-900">Select State</h5>
      <select
        onChange={(e) => changeCustomerState(e.target.value)}
        value={customerState}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        <option className="font-semibold" value="1">
          Karnataka
        </option>
        <option className="font-semibold" value="2">
          Kerala
        </option>
        <option className="font-semibold" value="3">
          Tamil Nadu
        </option>
        <option className="font-semibold" value="4">
          Maharashtra
        </option>
        <option className="font-semibold" value="5">
          Goa
        </option>
      </select>
      {getDistSpinner(customerType)}
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={goToNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2;
