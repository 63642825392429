import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer, deleteCustomer } from "../../reducers/customerReducer";
import { deleteCart } from "../../reducers/cartReducer";
import Navbar from "../navigation/Navbar";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";

const NewCall = () => {
  const [step, setStep] = useState(1);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  // If no valid user, force login
  if (!user) {
    history.push("/");
  }
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  // Force agent to enable gps
  if (!customer.gps) {
    history.push("/calls");
  }
  useEffect(() => {
    const updatedCustomer = {
      ...customer,
      state_code: "1",
      customer_type: "1",
    };
    dispatch(updateCustomer(updatedCustomer));
  }, []);

  const goToNextStep = () => setStep(step + 1);
  const goToPrevStep = () => setStep(step >= 2 ? step - 1 : step);
  const skipOrderStep = () => setStep(7);

  const cancelCall = () => {
    dispatch(deleteCustomer());
    dispatch(deleteCart());
    history.push("/calls");
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return <Step1 goToNextStep={goToNextStep} />;
      case 2:
        return (
          <Step2 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
        );
      case 3:
        return (
          <Step3 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
        );
      case 4:
        return (
          <Step4 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
        );
      case 5:
        return (
          <Step5
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            skipOrderStep={skipOrderStep}
          />
        );
      case 6:
        return (
          <Step6 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
        );
      case 7:
        return (
          <Step7 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar title="New Call" />
      <section className="mx-auto p-4">
        <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
          New Sales Call
        </h1>
        {renderStep(step)}
      </section>
      <button
        onClick={cancelCall}
        className={`${
          step !== 6 ? "absolute" : "relative"
        } bottom-4 block mx-auto w-full p-2 bg-red-900 text-white text-xl uppercase focus:outline-none cursor-pointer`}
      >
        Cancel
      </button>
    </>
  );
};

export default NewCall;
