import callService from "../services/call";

const callReducer = (state = [], action) => {
  switch (action.type) {
    case "NEW_CALL":
      return [...state, action.data];
    case "INIT_CALL":
      return action.data;
    default:
      return state;
  }
};

export const getCalls = async (partnerID) => {
  return async (dispatch) => {
    const calls = await callService.getCalls(partnerID);
    dispatch({
      type: "INIT_CALL",
      data: calls,
    });
  };
};

export const initCalls = (calls = []) => {
  return async (dispatch) => {
    dispatch({
      type: "INIT_CALL",
      data: calls,
    });
  };
};

export default callReducer;
