import React from "react";

const Call = ({ call }) => {
  const callDate = new Date(call.created);
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    dateStyle: "full",
    timeStyle: "short",
  }).format(callDate);

  return (
    <div className="bg-purple-900 my-1 text-white p-2 pl-4">
      <h3 className="mb-2 uppercase text-xl">{call.customer.organization}</h3>
      <div className="flex justify-start">
        <span className="mr-1">
          <svg
            className="w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
        <span className="mr-2">{formattedDate}</span>
      </div>
    </div>
  );
};

export default Call;
