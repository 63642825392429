import axios from "axios";
const baseURL = "https://salesapp.deccanplast.com/api";

const getReports = async (partnerID) => {
  try {
    const response = await axios.get(`${baseURL}/reports/partner/${partnerID}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const postReport = async (report, user) => {
  try {
    const response = await axios.post(`${baseURL}/reports/`, {
      report,
      user,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

const reportService = {
  getReports,
  postReport,
};

export default reportService;
