const customerReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_CUSTOMER":
      return action.data;
    case "INIT_CUSTOMER":
      return state;
    case "DELETE_CUSTOMER":
      return {};
    default:
      return state;
  }
};

export const updateCustomer = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CUSTOMER",
      data,
    });
  };
};

export const initCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: "INIT_CUSTOMER",
    });
  };
};

export const deleteCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: "DELETE_CUSTOMER",
      data: {},
    });
  };
};

export default customerReducer;
