import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../navigation/Navbar";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import reportService from "../../services/report";
import { createNotification } from "../../reducers/notificationReducer";

const NewReport = () => {
  const [step, setStep] = useState(1);
  let blankReport = {
    bp: {
      bp_market: "",
      bp_name: "",
      bp_location: "",
      bp_monthly_sales_target: "",
      bp_stock_value: "",
      bp_stock_qty: "",
      bp_recievable: "",
      bp_deccan_joint_sales: "",
    },
    calls: {
      today_sales_calls: "",
      monthly_cumulative_sales_calls: "",
      today_a_class_dealer_visits: "",
      today_a_class_direct_buyer_visits: "",
      today_premium_product_orders: "",
      today_other_product_orders: "",
      today_secondary_orders: "",
      monthly_cumulative_premium_product_orders: "",
      monthly_cumulative_other_product_orders: "",
      monthly_cumulative_secondary_orders: "",
    },
    market_share: {
      deccan: {
        sales_qty: "",
        sales_percentage: "",
      },
      nilkamal: {
        sales_qty: "",
        sales_percentage: "",
      },
      prima: {
        sales_qty: "",
        sales_percentage: "",
      },
      kissan: {
        sales_qty: "",
        sales_percentage: "",
      },
      national: {
        sales_qty: "",
        sales_percentage: "",
      },
      others: {
        sales_qty: "",
        sales_percentage: "",
      },
    },
    competitor_activity: {
      new_competior_products: "",
      competior_pricing: "",
      discounts: "",
    },
    market_feedback: {
      actions_required: [],
      suggestions: "",
    },
  };
  const dispatch = useDispatch();
  const [report, setReport] = useState(blankReport);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  // If no valid user, force login
  if (!user) {
    history.push("/");
  }

  const goToNextStep = () => setStep(step + 1);
  const goToPrevStep = () => setStep(step >= 2 ? step - 1 : step);
  const cancelReport = () => history.push("/");
  const submitReport = async () => {
    const results = await reportService.postReport(report, user);
    if (results && results.msg === "Success !") {
      dispatch(createNotification(`Report Created`, false, 10));
      history.push("/reports");
    } else {
      dispatch(createNotification(`Report Creation Failed`, true, 10));
    }
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <Step1
            cancelReport={cancelReport}
            goToNextStep={goToNextStep}
            report={report}
            updateReport={setReport}
          />
        );
      case 2:
        return (
          <Step2
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            report={report}
            updateReport={setReport}
          />
        );
      case 3:
        return (
          <Step3
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            report={report}
            updateReport={setReport}
          />
        );
      case 4:
        return (
          <Step4
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            report={report}
            updateReport={setReport}
          />
        );
      case 5:
        return (
          <Step5
            goToPrevStep={goToPrevStep}
            submitReport={submitReport}
            report={report}
            updateReport={setReport}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar title="New Report" />
      <section className="mx-auto p-4">
        <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
          New Daily Report
        </h1>
        {/* Sales Calls */}
        {renderStep(step)}
      </section>
    </>
  );
};

export default NewReport;
