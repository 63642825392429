export const mhs_categories = [
  { name: "Fisheries", code: 1 },
  { name: "Cashew", code: 2 },
  { name: "Others", code: 3 },
];
export const furniture_categories = [
  { name: "Retailer", code: 2 },
  { name: "Direct Buyer", code: 3 },
  { name: "Distributor", code: 1 },
];
export const furniture_subcategories = [
  { name: "Government", code: 1 },
  { name: "Educational Institute", code: 2 },
  { name: "Hospital", code: 3 },
  { name: "Marriage Business", code: 4 },
  { name: "Hospitality", code: 5 },
];

export const states_list = [
  { name: "Karnataka", code: 1 },
  { name: "Kerala", code: 2 },
  { name: "Tamil Nadu", code: 3 },
  { name: "Maharashtra", code: 4 },
  { name: "Goa", code: 5 },
];

export const goa_districts = [{ name: "Goa", code: 1 }];
export const maharashtra_districts = [
  { name: "Mumbai", code: 1 },
  { name: "Sangli", code: 2 },
  { name: "Sindhudurg", code: 3 },
  { name: "Thane", code: 4 },
  { name: "Solapur", code: 7 },
  { name: "Aurangabad", code: 9 },
  { name: "Jalgaon", code: 10 },
  { name: "Kolhapur", code: 6 },
  { name: "Nashik", code: 8 },
  { name: "Pune", code: 5 },
];

export const kerala_districts = [
  { name: "Kasargod", code: 1 },
  { name: "Kollam", code: 3 },
  { name: "Ernakulam", code: 4 },
  { name: "Kannur", code: 2 },
  { name: "Palakkad", code: 5 },
  { name: "Calicut", code: 6 },
  { name: "Malappuram", code: 7 },
  { name: "Thrissur", code: 8 },
  { name: "Wayanad", code: 9 },
];

export const tamil_nadu_districts = [
  { name: "Chennai", code: 1 },
  { name: "Salem", code: 2 },
  { name: "Madurai", code: 3 },
];

export const karnataka_districts = [
  { name: "Bagalkot", code: 23 },
  { name: "Bengaluru", code: 5 },
  { name: "Belgaum", code: 19 },
  { name: "Bellary", code: 20 },
  { name: "Bidar", code: 22 },
  { name: "Chamarajnagar", code: 11 },
  { name: "Chikmaglore", code: 7 },
  { name: "Chickballapur", code: 14 },
  { name: "Chitradurga", code: 13 },
  { name: "Dakshina Kannada", code: 1 },
  { name: "Davangere", code: 12 },
  { name: "Dharwad", code: 8 },
  { name: "Gadag", code: 24 },
  { name: "Gulbarga", code: 25 },
  { name: "Hassan", code: 18 },
  { name: "Haveri", code: 15 },
  { name: "Gulbarga", code: 1 },
  { name: "Kodagu", code: 17 },
  { name: "Kolar", code: 9 },
  { name: "Mandya", code: 10 },
  { name: "Mysore", code: 6 },
  { name: "Raichur", code: 26 },
  { name: "Ramnagara", code: 27 },
  { name: "Shivamogga", code: 4 },
  { name: "Tumkur", code: 21 },
  { name: "Udupi", code: 2 },
  { name: "Uttara Kannada", code: 3 },
  { name: "Vijayapura", code: 16 },
];

const helperData = {
  mhs_categories,
  furniture_categories,
  furniture_subcategories,
  states_list,
  goa_districts,
  tamil_nadu_districts,
  kerala_districts,
  maharashtra_districts,
  karnataka_districts,
};

export default helperData;
