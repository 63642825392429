import React, { useState, useEffect } from "react";
import callService from "../../services/call";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";
import Spinner from "../utility/Spinner";
import { loadingFalse, loadingTrue } from "../../reducers/loadingReducer";

const DistributorSpinner = ({ stateCode }) => {
  const [distributors, setDistributors] = useState([]);
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(loadingTrue());
      const results = await callService.getDistributors(parseInt(stateCode));
      setDistributors(results);
      if (!customer.distributor) {
        setCustomerDistributor(results[0]);
      }
      dispatch(loadingFalse());
    };
    fetchData();
  }, [stateCode]);

  const setCustomerDistributor = (distributor) => {
    const updatedCustomer = {
      ...customer,
      distributor,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const updateCustomerDistributor = (selectedDistributorId) => {
    const distributor = distributors.find(
      (dist) => dist._id === selectedDistributorId
    );
    console.log(distributor);
    const updatedCustomer = {
      ...customer,
      distributor,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  if (!distributors.length) {
    return <Spinner />;
  }
  return (
    <>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Select Distributor
      </h5>
      <select
        onChange={(e) => updateCustomerDistributor(e.target.value)}
        value={
          customer.distributor ? customer.distributor._id : distributors[0]._id
        }
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        {distributors.map((distributor) => (
          <option
            key={distributor._id}
            className="font-semibold"
            value={distributor._id}
          >
            {distributor.organization.toUpperCase()}
          </option>
        ))}
      </select>
    </>
  );
};

export default DistributorSpinner;
