const cartReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_CART":
      return action.data;
    case "INIT_CART":
      return action.data;
    case "DELETE_CART":
      return action.data;
    default:
      return state;
  }
};

export const updateCart = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CART",
      data,
    });
  };
};

export const initCart = () => {
  return (dispatch) => {
    dispatch({
      type: "INIT_CART",
      data: [],
    });
  };
};

export const deleteCart = () => {
  return (dispatch) => {
    dispatch({
      type: "DELETE_CART",
      data: [],
    });
  };
};

export default cartReducer;
