const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case "LOADING_TRUE":
      return action.data;
    case "LOADING_FALSE":
      return action.data;
    default:
      return state;
  }
};

export const loadingTrue = () => {
  return (dispatch) => {
    dispatch({
      type: "LOADING_TRUE",
      data: true,
    });
  };
};

export const loadingFalse = () => {
  return (dispatch) => {
    dispatch({
      type: "LOADING_FALSE",
      data: false,
    });
  };
};

export default loadingReducer;
