import React, { useState } from "react";
import DeccanLogo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { clearUser } from "../../reducers/authReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Navbar = ({ title }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(clearUser());
    history.push("/");
  };
  return (
    <nav className="bg-purple-900 h-16 flex justify-between items-center p-2">
      <svg
        onClick={() => setVisible(true)}
        className="w-6 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <h1 className="text-xl text-white justify-self-start">{title}</h1>
      <img src={DeccanLogo} className="h-12" alt="" />
      {visible ? (
        <>
          <div
            onClick={() => setVisible(false)}
            className="absolute w-2/4 right-0 top-0 h-screen bg-transparent z-40"
          ></div>
          <div className="w-2/4 bg-purple-900 h-screen absolute top-0 left-0 z-50 p-4">
            <svg
              onClick={() => setVisible(false)}
              className="w-6 text-white my-4 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <img src={DeccanLogo} className="h-16 m-auto" alt="" />
            <h3 className="text-center text-white text-2xl uppercase my-8">
              Menu
            </h3>
            <hr></hr>
            <nav>
              <ul>
                <Link to="/">
                  <li className="text-lg uppercase text-white my-4 text-center">
                    Home
                  </li>
                </Link>
                <Link to="/calls">
                  <li className="text-lg uppercase text-white my-4 text-center">
                    Calls
                  </li>
                </Link>
                <Link to="/reports">
                  <li className="text-lg uppercase text-white my-4 text-center">
                    Reports
                  </li>
                </Link>
                <li
                  onClick={logout}
                  className="text-lg uppercase text-white my-4 text-center"
                >
                  Logout
                </li>
              </ul>
            </nav>
          </div>
        </>
      ) : null}
    </nav>
  );
};

export default Navbar;
