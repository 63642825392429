import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

import { furniture_subcategories } from "../../helper";
import Spinner from "../utility/Spinner";

const SubCategorySpinner = ({ stateCode }) => {
  const [subcategoryCode, setSubCategoryCode] = useState("1");
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  useEffect(() => {
    const subcategory_details = furniture_subcategories.find(
      (cat) => cat.code === parseInt(subcategoryCode)
    );
    const subcategory = subcategory_details.name;

    const updatedCustomer = {
      ...customer,
      subcategory,
      subcategory_code: subcategoryCode,
    };
    dispatch(updateCustomer(updatedCustomer));
  }, [subcategoryCode]);

  if (!furniture_subcategories.length) {
    return <Spinner />;
  }
  return (
    <>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Select Sub Category
      </h5>
      <select
        onChange={(e) => setSubCategoryCode(e.target.value)}
        value={subcategoryCode}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        {furniture_subcategories.map((category, index) => (
          <option
            key={`${index}`}
            className="font-semibold"
            value={category.code}
          >
            {category.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default SubCategorySpinner;
