import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Navbar from "../navigation/Navbar";
import Notification from "../utility/Notification";
import Call from "./Call";
import callService from "../../services/call";
import { initCalls } from "../../reducers/callReducer";
import { initCustomer, updateCustomer } from "../../reducers/customerReducer";
import { initCart } from "../../reducers/cartReducer";
import FloatingButton from "../utility/FloatingButton";

const Calls = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  // If no valid user, force login
  if (!user) {
    history.push("/");
  }

  const dispatch = useDispatch();
  dispatch(initCustomer());
  dispatch(initCart());

  const customer = useSelector((state) => state.customer);
  const notification = useSelector((state) => state.notification);
  const [calls, setcalls] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setloading(true);
        const results = await callService.getCalls(user.id);
        console.log(results);
        setcalls(results);
        dispatch(initCalls(results));
        setloading(false);
      }
    };
    fetchData();
  }, [user, dispatch]);
  const showSalesCalls = () => {
    return calls && calls.length > 0 ? (
      calls.map((call) => (
        <Link key={call._id} to={`/calldetails/${call._id}`}>
          <Call call={call} />
        </Link>
      ))
    ) : (
      <h3 className="text-xl text-purple-900 text-center my-8">
        No Salescall Yet... !
      </h3>
    );
  };

  const showLoader = () => {
    return (
      <h3 className="text-xl text-purple-900 text-center my-8">Loading....</h3>
    );
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, positionError, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      });
    }
  };

  const positionError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.error("User denied the request for Geolocation.");
        alert("Please enable location to continue...");
        break;

      case error.POSITION_UNAVAILABLE:
        console.error("Location information is unavailable.");
        alert("Please enable location to continue...");
        break;

      case error.TIMEOUT:
        console.error("The request to get user location timed out.");
        alert("Timed out. Try again...");
        break;

      case error.UNKNOWN_ERROR:
        console.error("An unknown error occurred.");
        alert("Something went wrong...");
        break;
      default:
        console.error("Unknown Issue...");
        alert("Something went wrong...");
        break;
    }
  };

  const showPosition = (position) => {
    let gps = {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
    };
    if (gps) {
      const updatedCustomer = {
        ...customer,
        gps,
      };

      dispatch(updateCustomer(updatedCustomer));

      history.push("/newcall");
    } else {
      alert("Please enable location to continue...");
    }
  };

  return (
    <>
      <Navbar title="Sales Calls" />
      <section className="mx-auto">
        <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
          Sales Calls
        </h1>
        {notification ? <Notification notification={notification} /> : null}
        <div>{loading ? showLoader() : showSalesCalls()}</div>
        <FloatingButton handleClick={getLocation} />
      </section>
    </>
  );
};

export default Calls;
