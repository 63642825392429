import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

import {
  goa_districts,
  maharashtra_districts,
  kerala_districts,
  tamil_nadu_districts,
  karnataka_districts,
} from "../../helper";
import Spinner from "../utility/Spinner";

const DistrictSpinner = ({ stateCode }) => {
  const [districts, setDistricts] = useState([]);
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    const getDistricts = (stateCode) => {
      setDistricts([]);
      switch (parseInt(stateCode)) {
        case 1:
          setDistricts(karnataka_districts);
          break;
        case 2:
          setDistricts(kerala_districts);
          break;
        case 3:
          setDistricts(tamil_nadu_districts);
          break;
        case 4:
          setDistricts(maharashtra_districts);
          break;
        case 5:
          setDistricts(goa_districts);
          break;
        default:
          setDistricts(karnataka_districts);
      }
    };
    getDistricts(stateCode);
  }, [stateCode]);

  const setCustomerDistrict = (code) => {
    const updatedCustomer = {
      ...customer,
      district_code: code,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  if (!districts.length) {
    return <Spinner />;
  }
  return (
    <>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Select District
      </h5>
      <select
        onChange={(e) => setCustomerDistrict(e.target.value)}
        value={customer.district_code}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        {districts.map((district) => (
          <option
            key={`${district.name}-${district.code}`}
            className="font-semibold"
            value={district.code}
          >
            {district.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default DistrictSpinner;
