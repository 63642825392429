import React from "react";
import Notification from "../utility/Notification";
import { useSelector, useDispatch } from "react-redux";
import { createNotification } from "../../reducers/notificationReducer";
import callService from "../../services/call";
import { useHistory } from "react-router-dom";

const Step7 = ({ goToNextStep, goToPrevStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector((state) => state.customer);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const notification = useSelector((state) => state.notification);

  const {
    customer_type,
    organization,
    location,
    district,
    state,
    name,
    mobile,
    feedback,
  } = customer.customer_details;
  let category;
  if (customer.customer_novelty === "new") {
    category = customer.category;
  } else {
    category = customer.customer_details.category;
  }

  const validateNextStep = async () => {
    const results =
      customer_type === "1"
        ? await callService.postFurnitureSalesCall(customer, cart, user)
        : await callService.postMHSSalesCall(customer, cart, user);
    if (results && results.msg === "Success !") {
      dispatch(createNotification(`Salescall Created`, false, 10));
      history.push("/calls");
    } else {
      dispatch(createNotification(`Salescall Failed`, true, 10));
    }
  };
  const showOrderValue = () => {
    if (cart.length) {
      const total = cart.reduce(
        (sum, item) => item.product.price * item.quantity + sum,
        0
      );
      return `₹ ${total}`;
    } else {
      return "No Order";
    }
  };
  return (
    <div>
      <h3 className="text-center text-xl my-2 text-purple-900">
        Sales Call Summary
      </h3>
      {notification ? <Notification notification={notification} /> : null}
      <div className="bg-white shadow p-4">
        <h5 className="text-purple-900 my-1">
          Customer: <br />
          <span className="font-bold">{organization}</span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Type: <br />
          <span className="font-bold">
            {customer.customer_type === "1"
              ? "Furniture Customer"
              : "MHS Customer"}
          </span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Category: <br />
          <span className="font-bold">{category}</span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Location:
          <br />
          <span className="font-bold">{`${location}, ${district}, ${state}`}</span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Contact Person:
          <br />
          <span className="font-bold">{name}</span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Mobile:
          <br />
          <span className="font-bold">{mobile}</span>
        </h5>
        <h5 className="text-purple-900 my-1">
          Feedback:
          <br />
          <span className="font-bold">{feedback}</span>
        </h5>
        <h5 className="text-purple-900">
          Order Value: <br />
          <span className="font-bold">{showOrderValue()}</span>
        </h5>
      </div>
      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Step7;
