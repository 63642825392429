import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import authReducer from "./reducers/authReducer";
import notificationReducer from "./reducers/notificationReducer";
import callReducer from "./reducers/callReducer";
import customerReducer from "./reducers/customerReducer";
import cartReducer from "./reducers/cartReducer";
import loadingReducer from "./reducers/loadingReducer";
import reportReducer from "./reducers/reportReducer";

const reducer = combineReducers({
  user: authReducer,
  notification: notificationReducer,
  calls: callReducer,
  reports: reportReducer,
  customer: customerReducer,
  cart: cartReducer,
  loading: loadingReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
