import React, { useState } from "react";
import Product from "./Product";

const Category = ({ category, products }) => {
  const [visibility, setVisibility] = useState(true);
  const showDownChevron = () => (
    <svg
      onClick={() => setVisibility(true)}
      className="w-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );

  const showUpChevron = () => (
    <svg
      onClick={() => setVisibility(false)}
      className="w-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );

  const showProducts = () => (
    <div className="bg-white">
      {products.map((product) => (
        <Product key={product._id} product={product} />
      ))}
    </div>
  );

  return (
    <div className="my-4">
      <div className="bg-purple-900 text-white p-2 flex justify-between">
        <h3 className="text-xl">{category}</h3>
        {visibility ? showUpChevron() : showDownChevron()}
      </div>
      {visibility ? showProducts() : null}
    </div>
  );
};

export default Category;
