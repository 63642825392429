import React, { useState, useEffect } from "react";
import callService from "../../services/call";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";
import { loadingFalse, loadingTrue } from "../../reducers/loadingReducer";

const CustomerSpinner = () => {
  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const state_code = customer.state_code;
  const distributor_code = customer.distributor
    ? customer.distributor._id
    : null;
  const category_code = customer.category_code;
  const location = customer.location;
  const district_code = customer.district_code;
  const customer_type = customer.customer_type;

  useEffect(() => {
    const fetchData = async (
      state_code,
      district_code,
      distributor_code,
      category_code,
      location,
      customer_type
    ) => {
      dispatch(loadingTrue());
      let results;
      if (customer_type === "1") {
        results = await callService.getCustomers(
          state_code,
          distributor_code,
          category_code,
          location
        );
        setCustomers(results);
      } else {
        results = await callService.getMHSCustomers(
          state_code,
          district_code,
          category_code,
          location
        );
        setCustomers(results);
      }
      if (results) {
        setExistingCustomer(results[0]);
      }
      dispatch(loadingFalse());
    };
    fetchData(
      state_code,
      district_code,
      distributor_code,
      category_code,
      location,
      customer_type
    );
  }, [
    state_code,
    district_code,
    distributor_code,
    category_code,
    location,
    customer_type,
  ]);

  const setExistingCustomer = (customer_details) => {
    const updatedCustomer = {
      ...customer,
      customer_details,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const updateExistingCustomer = (id) => {
    const customer_details = customers.find((cust) => cust._id === id);
    const updatedCustomer = {
      ...customer,
      customer_details,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  console.log(customers);

  if (!customers.length) {
    return (
      <h5 className="text-center text-lg my-2 text-purple-900">
        No Customers Found !
      </h5>
    );
  } else {
    return (
      <>
        <h5 className="text-center text-lg my-2 text-purple-900">
          Select Customer
        </h5>
        <select
          onChange={(e) => updateExistingCustomer(e.target.value)}
          value={
            customer.customer_details
              ? customer.customer_details._id
              : customers[0]._id
          }
          className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        >
          {customers.map((customer, index) => (
            <option key={index} className="font-semibold" value={customer._id}>
              {customer.organization.toUpperCase()}
            </option>
          ))}
        </select>
      </>
    );
  }
};

export default CustomerSpinner;
