import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";

const Step1 = ({ goToNextStep, goToPrevStep }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = customer.customer_type;

  const changeCustomerType = (type) => {
    const updatedCustomer = {
      ...customer,
      customer_type: type,
    };

    dispatch(updateCustomer(updatedCustomer));
  };

  return (
    <div>
      <h3 className="text-center text-xl my-4 text-purple-900">
        Select Customer Type
      </h3>
      <select
        onChange={(e) => changeCustomerType(e.target.value)}
        value={customerType}
        className="my-4 px-4 py-3 rounded-lg w-full font-semibold"
      >
        <option className="font-semibold" value="1">
          Furniture
        </option>
        <option className="font-semibold" value="2">
          MHS
        </option>
      </select>
      <button
        onClick={goToNextStep}
        className="my-4 bg-purple-600 w-full py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
      >
        Continue
      </button>
    </div>
  );
};

export default Step1;
