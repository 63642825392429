import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../reducers/authReducer";
import { createNotification } from "../../reducers/notificationReducer";

import DeccanLogo from "../../assets/logo.png";
import Notification from "../utility/Notification";

const Login = () => {
  const dispatch = useDispatch();
  //   const user = useSelector((state) => state.user);
  const notification = useSelector((state) => state.notification);

  const [credentials, setCredentials] = useState({
    username: "",
    passpin: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(loginUser(credentials));
    } catch (exception) {
      console.log("exception", exception);
      dispatch(createNotification(`Invalid Credentials`, true, 5));
    }
  };

  return (
    <section className="w-11/12 mx-auto shadow p-4">
      <img
        className="block mx-auto w-8/12 my-8"
        src={DeccanLogo}
        alt="Deccan Plast Industries Logo"
      />
      <h1 className="text-center text-2xl my-4 text-purple-900 font-semibold">
        Login
      </h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label
            className="block mx-auto w-11/12 mt-2 mb-1 text-purple-900"
            htmlFor="login_user_id"
          >
            Username
          </label>
          <input
            className="block mx-auto w-11/12 mb-4 p-2 rounded-lg"
            type="text"
            name=""
            id="login_user_id"
            placeholder="User Id"
            onChange={(e) =>
              setCredentials({ ...credentials, username: e.target.value })
            }
          />
        </div>
        <div>
          <label
            className="block mx-auto w-11/12 mt-2 mb-1 text-purple-900"
            htmlFor="login_user_pin"
          >
            Pin
          </label>
          <input
            className="block mx-auto w-11/12 mb-4 p-2 rounded-lg"
            type="password"
            name=""
            id="login_user_pin"
            placeholder="Pin"
            onChange={(e) =>
              setCredentials({ ...credentials, passpin: e.target.value })
            }
          />
        </div>
        <button
          className="bg-purple-900 text-white p-2 block mx-auto w-11/12 my-8 uppercase"
          type="submit"
        >
          Login
        </button>
      </form>
      {notification ? <Notification notification={notification} /> : null}
    </section>
  );
};

export default Login;
