import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCustomer } from "../../reducers/customerReducer";
import CategorySpinner from "./CategorySpinner";
import CustomerSpinner from "./CustomerSpinner";
import SubCategorySpinner from "./SubCategorySpinner";

const Step4 = ({ goToNextStep, goToPrevStep }) => {
  const [categoryCode, setCategoryCode] = useState("2");
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const location_type = customer.location_type;
  const [customerNovelty, setCustomerNovelty] = useState(
    customer.customer_novelty ? customer.customer_novelty : location_type
  );

  const inputRef = useRef(null);

  const updateCustomerType = (customer_novelty) => {
    setCustomerNovelty(customer_novelty);
    const updatedCustomer = {
      ...customer,
      customer_novelty,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const newCustomerInput = () => {
    return (
      <>
        <h5 className="text-center text-lg my-2 text-purple-900">
          Enter New Customer Name
        </h5>
        <input
          onChange={(e) => updateCustomerInfo(e.target.value, customerNovelty)}
          type="text"
          ref={inputRef}
          value={
            customer.customer_novelty === "new" &&
            customer.customer_details &&
            customer.customer_details.organization
              ? customer.customer_details.organization
              : ""
          }
          placeholder="Enter name of the customer"
          className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
        />
      </>
    );
  };

  const currentCategoryCode = (code) => {
    setCategoryCode(code);
  };

  const updateCustomerInfo = (organization, customer_novelty) => {
    const { district, district_code, state, state_code } = customer.distributor;
    const location = customer.location;
    const customer_details = {
      organization,
      id: "NEW000",
      location,
      district,
      district_code,
      state,
      state_code,
      target: 0,
    };
    const updatedCustomer = {
      ...customer,
      customer_details,
      customer_novelty,
    };
    dispatch(updateCustomer(updatedCustomer));
  };

  const validateNextStep = () => {
    if (customerNovelty === "new") {
      if (inputRef.current.value) {
        goToNextStep();
      } else {
        inputRef.current.classList.add("border-4");
        inputRef.current.classList.add("border-red-500");
      }
    } else {
      goToNextStep();
    }
  };

  return (
    <div>
      <h3 className="text-center text-xl my-2 text-purple-900">
        Customer Details
      </h3>
      <h5 className="text-center text-lg my-2 text-purple-900">
        Type of Customer
      </h5>
      <select
        onChange={(e) => updateCustomerType(e.target.value)}
        value={customerNovelty}
        className="my-2 px-4 py-3 rounded-lg w-full font-semibold"
      >
        <option className="font-semibold" value="existing">
          Existing
        </option>
        <option className="font-semibold" value="new">
          New
        </option>
      </select>
      <CategorySpinner currentCategoryCode={currentCategoryCode} />
      {customerNovelty === "existing" ? (
        <CustomerSpinner />
      ) : (
        newCustomerInput()
      )}
      {customerNovelty === "new" && categoryCode === "3" ? (
        <SubCategorySpinner />
      ) : null}

      <div className="my-8 flex justify-around">
        <button
          onClick={goToPrevStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Prev
        </button>
        <button
          onClick={validateNextStep}
          className="bg-purple-600 py-2 px-4 rounded cursor-pointer focus:outline-none mx-auto uppercase text-xl text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step4;
